import React, { useContext, useEffect, useState } from 'react'
import { Tooltip } from "react-tooltip";
import PatientDetails from '../../../Components/PatientInformation';
import SaveAndNavigate from '../../../Components/SaveAndNavigate';
import { PageDesignConstants, SideMenuItems, HTTPResponse, ToastMessageType, ToastMessages, RRMMCohortTreatment } from '../../../Helpers/Enums';
import SideBarMenu from '../../../Components/SideBarMenu';
import ErrorField from "../../../Components/ErrorField";
import { CommonError_Msgs, BispecificCartTreatmentCohort_Msgs, hyperlinkTextLineOfTherapy } from "../../../Helpers/HelperText";
import { AppContext } from "../../../Contexts/AppContextProvider";
import { GetLocalStorageData, GetLoggedInUserID, isStringEmpty, AllowNumberWithOneDecimal, AllowOnlyOneDecimal, validateMeasurementDate } from "../../../Helpers/Utilities";
import { BispecificsCartTreatmentCohortService } from "../../../WebApiServices/BispecificsCartTreatmentCohort.service";
import InfoPopUp from "../../../Components/InfoPopUp";
import DateControl from "../../../Components/DateControl";
import DatePickerComponent from "../../../Components/DatePickerComponent";
import dayjs from "dayjs";

const BispecificsCartTreatmentCohort = () => {
    const [ndmmAvailable, setNdmmAvailable] = useState(false);
    const navigatePaths = {
        prevPage: ndmmAvailable ? "/NDMMTreatmentCohort" : "/CohortDefiningTreatment",
        currPage: "/BispecificsCartTreatmentCohort",
        nextPage: "/LineOfTherapy"
    }

    const pid = GetLocalStorageData("patientId") ?? 0;

    const storedData = GetLocalStorageData("CohortTreatment");

    let cohortTreatmentModelListt = [];
    let cohortTreatmentModelListtndmm = [];
    if (storedData) {
        cohortTreatmentModelListt = JSON.parse(storedData);
        cohortTreatmentModelListtndmm = cohortTreatmentModelListt.filter(c => c.isEligible==true && c.ndmm==true);
        cohortTreatmentModelListt = cohortTreatmentModelListt.filter(c => c.isEligible==true && c.ndmm==false);
    }
    const { ShowToast, ToggleLoader, HandleSessionTimeout,isSideMenuActive,setIsSideMenuActive  } = useContext(AppContext);
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);
    const [showHideHyperlinkModal, setShowHideHyperlinkModal] = useState(false);


    const cartTreatment = {
        bispecificTreatmentcohortid: 0,
        cohorttreatmentid: 0,
        cohortTreatmentName: "",
        cohortIndexDate: "",
        supscripttext: "",
        endOfFollowUpDate:"",
        lOT: "",
        discontinueAgent: null,
        firstDate: null,
        lastDate: null,
        lastDateRadio: null,
        lastDateUnk: null,
        noOfAdmission: null,
        noOfAdmissionRadio: null,
        noOfAdmissionUnk: null,
        doseAdministrationRadiomg: null,
        doseAdministrationmg: null,
        doseAdministrationRadiokg: null,
        doseAdministrationkg: null,
        doseAdministrationRadiomgm: null,
        doseAdministrationmgm: null,
        doseAdministrationRadiocell: null,
        doseAdministrationcell: null,
        reasonForDiscontinuingMedications: [{
            bispecificCohortId: 0,
            cohorttreatmentid: 0,
            reasonid: 0,
            reasonName: null,
            reasonText: null,
            supscripttext: "",
            isSelected: false,
            reasonUnk: false
        }
        ],

        showColBError: false,
        showColBDuplicateLotError: false,
        showColDError: false,
        showColD_SEFDateError: false,
        showColD_LEFDateError:false,
        showColD_EFDateError: false,
        showColD_InvalidDate: false,
        showColEError: false,
        showColFError: false,
        showColG: false,
        showColGError: false,
        showColGOthError: false,
        showColHError: false,
    };

    const [bispecificTreatment, setbispecificTreatment] = useState([cartTreatment]);


    useEffect(() => {
        async function FetchData() {
            //Set previous path           
            setNdmmAvailable(cohortTreatmentModelListtndmm.length > 0)
            
            if (!isNaN(pid) && Number(pid) !== 0) {
                ToggleLoader(true);
                await LoadData();

                ToggleLoader(false);
            }
        }
        FetchData();
    }, []);




    const LoadData = async () => {
   
        try {
            if (pid > 0) {
                const response = await BispecificsCartTreatmentCohortService.GetBispecificCartTreatmentCohortData(
                    pid
                );

                if (response?.status === HTTPResponse.OK) {
                    const { data } = response;

                    
                    
                    const cohortTreatmentMap = new Map();
                    if (cohortTreatmentModelListt) {

                        cohortTreatmentModelListt.forEach((item) => {
                            if (item.isEligible) {
                                cohortTreatmentMap.set(item.cohortTreatmentID, {
                                    cohortTreatmentName: item.cohortTreatmentName,
                                    cohortIndexDate: item.cohortIndexDate,
                                    cohortTreatmentNameSuperscript: item.cohortTreatmentNameSuperscript
                                });
                            }
                        });
                    }
                   
                    let repeaterData = [];

                    // Check if there is existing Bispecific cart data
                    if (
                        data.medicationTreatmentLists &&
                        data.medicationTreatmentLists.length !== 0 
                    ) {
                        repeaterData = data.medicationTreatmentLists.map(
                            (item) => {
                               
                                const cohortTreatment =
                                    cohortTreatmentMap.get(item.cohortTreatmentId) || {};
                              

                                const discontinueAgentsLst = item.reasonForDiscontinuingMedications.map((AgentItem) => ({
                                    bispecificCohortId: AgentItem.bispecificCohortId,
                                    cohorttreatmentid: item.cohortTreatmentId,
                                    reasonid: AgentItem.reasonid,
                                    reasonName: AgentItem.reasonName,
                                    reasonText: AgentItem.reasonText,
                                    supscripttext: AgentItem.supscripttext,
                                    isSelected: AgentItem.isSelected,
                                    reasonUnk: AgentItem.reasonUnk
                                }));
                                return {
                                    cohorttreatmentid: item.cohortTreatmentId,
                                    cohortTreatmentName: cohortTreatment.cohortTreatmentName || null,
                                    cohortIndexDate: cohortTreatment.cohortIndexDate || null,
                                    supscripttext: cohortTreatment.cohortTreatmentNameSuperscript,
                                    bispecificTreatmentcohortid: item.bispecificTreatmentcohortid,
                                    endOfFollowUpDate: data.endOfFollowUpDate,
                                    lOT: item.lot,
                                    firstDate: cohortTreatment.cohortIndexDate || null,
                                    lastDate: item.lastDate,
                                    lastDateRadio: item.lastDateRadio,
                                    lastDateUnk: item.lastDateUnk,
                                    noOfAdmission: item.noOfAdmission,
                                    noOfAdmissionRadio: item.noOfAdmissionRadio,
                                    noOfAdmissionUnk: item.noOfAdmissionUnk,
                                    doseAdministrationRadiomg: item.doseAdministrationRadiomg,
                                    doseAdministrationmg: item.doseAdministrationmg,
                                    doseAdministrationRadiokg: item.doseAdministrationRadiokg,
                                    doseAdministrationkg: item.doseAdministrationkg,
                                    doseAdministrationRadiomgm: item.doseAdministrationRadiomgm,
                                    doseAdministrationmgm: item.doseAdministrationmgm,
                                    doseAdministrationRadiocell: item.doseAdministrationRadiocell,
                                    doseAdministrationcell: item.doseAdministrationcell,
                                    discontinueAgent: item.discontinueAgent,
                                    reasonForDiscontinuingMedications:discontinueAgentsLst,
                                    showColBError: false,
                                    showColBDuplicateLotError:false,
                                    showColDError: false,
                                    showColD_SEFDateError: false,
                                    showColD_LEFDateError: false,
                                    showColD_EFDateError: false,
                                    showColD_InvalidDate:false,
                                    showColEError: false,
                                    showColHError:false,
                                    showColFError: false,
                                    showColG: item.discontinueAgent==1?true:false,
                                    showColGError: false,
                                    showColGOthError: false
                                };
                            }
                        );



                        


                        // Merge with cohortTreatmentModelListt
                        cohortTreatmentModelListt.forEach((cohort) => {
                            if (
                                !repeaterData.some(
                                    (item) => item.cohorttreatmentid === cohort.cohortTreatmentID
                                )
                            ) {
                                const discontinueAgentsLst = data.reasonForDiscontinuingMedications.map((AgentItem) => ({
                                    bispecificCohortId: AgentItem.bispecificCohortId,
                                    cohorttreatmentid: cohort.cohortTreatmentID,
                                    reasonid: AgentItem.reasonid,
                                    reasonName: AgentItem.reasonName,
                                    reasonText: AgentItem.reasonText,
                                    supscripttext: AgentItem.supscripttext,
                                    isSelected: AgentItem.isSelected,
                                    reasonUnk: AgentItem.reasonUnk
                                }));

                                repeaterData.push({
                                    cohorttreatmentid: cohort.cohortTreatmentID,
                                    cohortTreatmentName: cohort.cohortTreatmentName,
                                    cohortIndexDate: cohort.cohortIndexDate,
                                    supscripttext: cohort.cohortTreatmentNameSuperscript,
                                    endOfFollowUpDate: data.endOfFollowUpDate,
                                    bispecificTreatmentcohortid: 0,
                                    lOT: null,
                                    firstDate: cohort.cohortIndexDate,
                                    lastDate: null,
                                    lastDateRadio: null,
                                    lastDateUnk: null,
                                    noOfAdmission: null,
                                    noOfAdmissionRadio: null,
                                    doseAdministrationRadiomg:null,
                                    noOfAdmissionUnk: null,
                                    doseAdministrationmg: null,
                                    doseAdministrationRadiokg: null,
                                    doseAdministrationkg: null,
                                    doseAdministrationRadiomgm: null,
                                    doseAdministrationmgm: null,
                                    doseAdministrationRadiocell: null,
                                    doseAdministrationcell: null,
                                    discontinueAgent: null,
                                    reasonForDiscontinuingMedications: discontinueAgentsLst,
                                    showColBError: false,
                                    showColBDuplicateLotError:false,
                                    showColDError: false,
                                    showColD_InvalidDate:false,
                                    showColD_SEFDateError: false,
                                    showColD_LEFDateError: false,
                                    showColD_EFDateError:false,
                                    showColEError: false,
                                    showColHError:false,
                                    showColFError: false,
                                    showColG: false,
                                    showColGError: false,
                                    showColGOthError: false
                                });
                            }
                        });
                       
                        setbispecificTreatment(repeaterData);
                    } else {
                        // No bispecificdata
                  
                        if (cohortTreatmentModelListt != null) {
                        
                            cohortTreatmentModelListt.forEach((cohort) => {
                                const discontinueAgentsLst = data.reasonForDiscontinuingMedications.map((AgentItem) => ({
                                    bispecificCohortId: AgentItem.bispecificCohortId,
                                    cohorttreatmentid: cohort.cohortTreatmentID,
                                    reasonid: AgentItem.reasonid,
                                    reasonName: AgentItem.reasonName,
                                    reasonText: AgentItem.reasonText,
                                    supscripttext: AgentItem.supscripttext,
                                    isSelected: AgentItem.isSelected,
                                    reasonUnk: AgentItem.reasonUnk
                                }));
                          

                                repeaterData.push({
                                cohorttreatmentid: cohort.cohortTreatmentID,
                                cohortTreatmentName: cohort.cohortTreatmentName,
                                cohortIndexDate: cohort.cohortIndexDate,
                                supscripttext: cohort.cohortTreatmentNameSuperscript,
                                reasonForDiscontinuingMedications:discontinueAgentsLst,
                                    bispecificTreatmentcohortid: 0,
                                    endOfFollowUpDate: data.endOfFollowUpDate,
                                lOT: null,
                                firstDate: cohort.cohortIndexDate,
                                lastDate: null,
                                lastDateRadio: null,
                                lastDateUnk: null,
                                noOfAdmission: null,
                                    noOfAdmissionRadio: null,
                                    noOfAdmissionUnk: null,
                                    doseAdministrationRadiomg:null,
                                    doseAdministrationmg: null,
                                    doseAdministrationRadiokg: null,
                                    doseAdministrationkg: null,
                                    doseAdministrationRadiomgm: null,
                                    doseAdministrationmgm: null,
                                    doseAdministrationRadiocell: null,
                                    doseAdministrationcell: null,
                                discontinueAgent: null,
                                    showColBError: false,
                                    showColBDuplicateLotError:false,
                                    showColDError: false,
                                    showColD_InvalidDate:false,
                                    showColD_SEFDateError: false,
                                    showColD_LEFDateError: false,
                                    showColD_EFDateError:false,
                                showColEError: false,
                                    showColFError: false,
                                    showColHError:false,
                                showColG: false,
                                showColGError: false,
                                showColGOthError: false
                                });

                            });
                          

                            setbispecificTreatment(repeaterData);
                        }
                    }
                }
                else if (response?.status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                }
            }
        } catch (error) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    };

    const HandleRepeaterFieldChange = (e, agentName, reasonName, parentIdx, itemidx) => {

        let { name, id, value } = e.target;
        let updatedArray = [...bispecificTreatment];
        const index = id.split('_').pop(); // Extract the index from the id
        switch (name) {

            case `q26b_${agentName}_lottxt_${index}`: {
                updatedArray[index].lOT = !isStringEmpty(value) ?Number(value):"";
                updatedArray[index].showColBError = false;
                updatedArray[index].showColBDuplicateLotError = false;
            }
                break;
            case `q26d_LRadio_${agentName}_${index}`: {
                updatedArray[index].lastDateRadio = 1;
                updatedArray[index].lastDate = null;
                updatedArray[index].lastDateUnk = "";
                updatedArray[index].showColDError = false;
                updatedArray[index].showColD_SEFDateError = false;
                updatedArray[index].showColD_LEFDateError = false;
                updatedArray[index].showColD_EFDateError = false;
                updatedArray[index].showColD_InvalidDate = false;
            }
                break;
            case `q26d_${agentName}_LUnk_${index}`: {
                updatedArray[index].lastDateRadio = "";
                updatedArray[index].lastDate = null;
                updatedArray[index].lastDateUnk = Number(value);
                updatedArray[index].showColDError = false;
                updatedArray[index].showColD_SEFDateError = false;
                updatedArray[index].showColD_LEFDateError = false;
                updatedArray[index].showColD_EFDateError = false;
                updatedArray[index].showColD_InvalidDate = false;
            }
                break;
            case `q26e_${agentName}_Noa_${index}`: {
                updatedArray[index].noOfAdmissionRadio = Number(value);
                updatedArray[index].noOfAdmission = "";
                updatedArray[index].noOfAdmissionUnk = "";
                updatedArray[index].showColEError = false;
            }
                break;
            case `q26e_${agentName}_Noatxt_${index}`: {
                updatedArray[index].noOfAdmissionRadio = 1;
                updatedArray[index].noOfAdmission = !isStringEmpty(value) ? Number(value) : "";
                updatedArray[index].noOfAdmissionUnk = "";
                updatedArray[index].showColEError = false;
            }
                break;
            case `q26e_${agentName}_NoaUnk_${index}`: {
                updatedArray[index].noOfAdmissionRadio = "";
                updatedArray[index].noOfAdmission = "";
                updatedArray[index].noOfAdmissionUnk = Number(value);
                updatedArray[index].showColEError = false;
            }
                break;
            case `q26h1_${agentName}_mg_${index}`: {
                updatedArray[index].doseAdministrationRadiomg = Number(value);
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].doseAdministrationRadiokg =null;
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomgm = null;
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].doseAdministrationRadiocell = null;
                updatedArray[index].doseAdministrationcell = "";
                updatedArray[index].showColHError = false;
            }
                break;
            case `q26h1_${agentName}_mgtxt_${index}`: {
                updatedArray[index].doseAdministrationRadiomg = 1;
                updatedArray[index].doseAdministrationmg = !isStringEmpty(value) ? Number(value) : "";
                updatedArray[index].doseAdministrationRadiokg = null;
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomgm = null;
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].doseAdministrationRadiocell = null;
                updatedArray[index].doseAdministrationcell = "";
                updatedArray[index].showColHError = false;
            }
                break;
            case `q26h2_${agentName}_kg_${index}`: {
                updatedArray[index].doseAdministrationRadiokg = Number(value);
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomg = null;
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].doseAdministrationRadiomgm = null;
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].doseAdministrationRadiocell = null;
                updatedArray[index].doseAdministrationcell = "";
                updatedArray[index].showColHError = false;
            }
                break;
            case `q26h2_${agentName}_kgtxt_${index}`: {
                updatedArray[index].doseAdministrationRadiokg = 1;
                updatedArray[index].doseAdministrationkg = !isStringEmpty(value) ? Number(value) : "";
                updatedArray[index].doseAdministrationRadiomg = null;
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].doseAdministrationRadiomgm = null;
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].doseAdministrationRadiocell = null;
                updatedArray[index].doseAdministrationcell = "";
                updatedArray[index].showColHError = false;
            }
                break;
            case `q26h3_${agentName}_m_${index}`: {
                updatedArray[index].doseAdministrationRadiomgm = Number(value);
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].doseAdministrationRadiokg = null;
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomg = null;
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].doseAdministrationRadiocell = null;
                updatedArray[index].doseAdministrationcell = "";
                updatedArray[index].showColHError = false;
            }
                break;
            case `q26h3_${agentName}_mtxt_${index}`: {
                updatedArray[index].doseAdministrationRadiomgm = 1;
                updatedArray[index].doseAdministrationmgm = !isStringEmpty(value) ? Number(value) : "";
                updatedArray[index].doseAdministrationRadiokg = null;
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomg = null;
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].doseAdministrationRadiocell = null;
                updatedArray[index].doseAdministrationcell = "";
                updatedArray[index].showColHError = false;
            }
                break;
            case `q26h4_${agentName}_cell_${index}`: {
                updatedArray[index].doseAdministrationRadiocell = Number(value);
                updatedArray[index].doseAdministrationcell = "";
                updatedArray[index].doseAdministrationRadiokg = null;
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomg = null;
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].doseAdministrationRadiomgm = null;
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].showColHError = false;
            }
                break;
            case `q26h4_${agentName}_celltxt_${index}`: {
                updatedArray[index].doseAdministrationRadiocell = 1;
                updatedArray[index].doseAdministrationcell = !isStringEmpty(value) ? Number(value) : "";
                updatedArray[index].doseAdministrationRadiokg = null;
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomg = null;
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].doseAdministrationRadiomgm = null;
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].showColHError = false;
            }
                break;


            case `q26f_${agentName}_PdaYes_${index}`:
            case `q26f_${agentName}_PdaNo_${index}`:
            case `q26f_${agentName}_PdaUnk_${index}`: {
                updatedArray[index].discontinueAgent = Number(value);
                updatedArray[index].showColFError = false;
                updatedArray[index].showColGError = false;
                updatedArray[index].showColGOthError = false;
                updatedArray[index].showColG = Number(value) === 1 ? true : false;
                if (Number(value) !== 1)
                    updatedArray[index].reasonForDiscontinuingMedications = updatedArray[index].reasonForDiscontinuingMedications.map(item => {
                        return { ...item, isSelected: false, reasonUnk: "" };
                    });
            }
                break;
            case `discont_${agentName}_${reasonName}_${itemidx}`: {
                updatedArray[parentIdx].reasonForDiscontinuingMedications[itemidx].cohorttreatmentid = updatedArray[parentIdx].cohorttreatmentid;
                updatedArray[parentIdx].reasonForDiscontinuingMedications[itemidx].isSelected = !updatedArray[parentIdx].reasonForDiscontinuingMedications[itemidx].isSelected;
                updatedArray[parentIdx].reasonForDiscontinuingMedications[0].reasonUnk = "";
                updatedArray[parentIdx].showColGError = false;
                updatedArray[parentIdx].showColGOthError = false;
                if (updatedArray[parentIdx].reasonForDiscontinuingMedications[itemidx].reasonName.includes('Other')) {
                    if (updatedArray[parentIdx].reasonForDiscontinuingMedications[itemidx].isSelected == false)
                        updatedArray[parentIdx].reasonForDiscontinuingMedications[itemidx].reasonText = "";
                }
            }
                break;
            case `discont_unk_${agentName}_${parentIdx}`: {
                updatedArray[parentIdx].showColGError = false;
                updatedArray[parentIdx].showColGOthError = false;
                updatedArray[parentIdx].reasonForDiscontinuingMedications = updatedArray[parentIdx].reasonForDiscontinuingMedications.map(item => {
                    return { ...item, isSelected: false, reasonUnk: 1, reasonText:"" };
                });
            }
                break;
            case `discont_${agentName}_${reasonName}txt_${itemidx}`: {

                updatedArray[parentIdx].showColGError = false;
                updatedArray[parentIdx].showColGOthError = false;
                updatedArray[parentIdx].reasonForDiscontinuingMedications[0].reasonUnk = "";
                updatedArray[parentIdx].reasonForDiscontinuingMedications[itemidx].isSelected = (updatedArray[parentIdx].reasonForDiscontinuingMedications[itemidx].reasonName.includes('Other')) ? true : false;

                if (value.length <= 300) {
                    updatedArray[parentIdx].reasonForDiscontinuingMedications[itemidx].reasonText = value;
                }
                else {
                    updatedArray[parentIdx].reasonForDiscontinuingMedications[itemidx].reasonText = value.substring(0, 300);
                }
            }
                break;
            default:
                break;
        }
        setbispecificTreatment(updatedArray);
    }


    const HandleDateChange = (name, date, optionId, monthOrYearSelect) => {
        
        let updatedArray = [...bispecificTreatment];
        const idx = name.split('_').pop();
        
        switch (name) {
          
            case `q26d_LDate_${idx}`: {
                updatedArray[idx].lastDateRadio = 1;
                updatedArray[idx].lastDate = date;
                updatedArray[idx].lastDateUnk = "";
                updatedArray[idx].showColDError = false;
                updatedArray[idx].showColD_SEFDateError = false;
                updatedArray[idx].showColD_LEFDateError = false;
                updatedArray[idx].showColD_EFDateError = false;
                updatedArray[idx].showColD_InvalidDate = false;
            }
                break;
            default:
                break;
        }
        setbispecificTreatment(updatedArray);
    };
    const SavePage = async (validate) => {

        try {
            ToggleLoader(true);

            let isValid = validate ? ValidateRepeaterData(bispecificTreatment) : true;
      

            if (isValid) {
                setShowTopErrMsg(false);


                const RRMMData = bispecificTreatment.map((item) => ({
                    cohorttreatmentid: item.cohorttreatmentid,
                    cohortTreatmentName: item.cohortTreatmentName || null,
                    cohortIndexDate: item.cohortIndexDate || null,
                    supscripttext: item.cohortTreatmentNameSuperscript,
                    bispecificTreatmentcohortid: item.bispecificTreatmentcohortid,
                    lOT: item.lOT,
                    firstDate: item.firstDate,
                    lastDate: item.lastDate,
                    lastDateRadio: item.lastDateRadio,
                    lastDateUnk: item.lastDateUnk,
                    noOfAdmission: item.noOfAdmission,
                    noOfAdmissionRadio: item.noOfAdmissionRadio,
                    noOfAdmissionUnk: item.noOfAdmissionUnk,
                    doseAdministrationRadiomg: item.doseAdministrationRadiomg,                 
                    doseAdministrationmg: item.doseAdministrationmg,
                    doseAdministrationRadiokg: item.doseAdministrationRadiokg,
                    doseAdministrationkg: item.doseAdministrationkg,
                    doseAdministrationRadiomgm: item.doseAdministrationRadiomgm,
                    doseAdministrationmgm: item.doseAdministrationmgm,
                    doseAdministrationRadiocell: item.doseAdministrationRadiocell,
                    doseAdministrationcell: item.doseAdministrationcell,
                    discontinueAgent: item.discontinueAgent,
                    reasonForDiscontinuingMedications: item.reasonForDiscontinuingMedications,                    
                }));


                const RRMMModel = {
                    Patientid: pid,
                    loggedInUserId: GetLoggedInUserID(),
                    isValidated: validate,
                    medicationTreatmentLists: RRMMData,


                };

                if (pid > 0) {
                    let response = await BispecificsCartTreatmentCohortService.SaveBispecificCartTreatmentCohortData(
                        RRMMModel
                    );

                    if (response.status !== HTTPResponse.OK) {

                        if (response?.status === HTTPResponse.Unauthorized) {
                            HandleSessionTimeout();

                        } else throw response.error;
                    } else {
                        ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                        await LoadData(pid);

                        //const Data = GetLocalStorageData("CohortTreatment");
                        //let cohortTreatmentModelListtndmm = [];
                        //if (Data) {
                        //    cohortTreatmentModelListtndmm = cohortTreatmentModelListt.filter(c => c.isEligible == true && c.ndmm == true);
                        //}
                        //navigatePaths.prevPage = cohortTreatmentModelListtndmm.length > 0 ? "/NDMMTreatmentCohort" : "/Comorbidities";
                        
                    }

                    ToggleLoader(false);
                }
                return true;
            } else {
                ToggleLoader(false);
                setShowTopErrMsg(true);
                return false;
            }
        } catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
        }

    }

    // Function to get lots above the matching index for a given cohorttreatmentid
    const getLotsAboveMatchingIndex = (cohorttreatmentid, bispecifictreatment) => {

        const sortedList = bispecifictreatment
            .sort((a, b) => a.cohorttreatmentid - b.cohorttreatmentid)  // Sort by cohorttreatmentid
            .map(row => ({
                cohorttreatmentid: row.cohorttreatmentid,
                lot: row.lOT
            }));

        // Find the index of the first matching cohorttreatmentid
        const matchIndex = sortedList.findIndex(row => row.cohorttreatmentid === cohorttreatmentid);
    
        // Get all the lots above the matching index
        return sortedList
            .slice(0, matchIndex)  // Take the elements above the matching index
            .map(row => row.lot);  // Extract the lot values
    }

    const DateInRange = (date, minDate, maxDate) => {
        return dayjs(date).isAfter(minDate) && dayjs(date).isBefore(maxDate);
    };
    const ValidateRepeaterData = (bispecifictreatment) => {

        let result = true;
      
        const updatedRepeater = bispecifictreatment.map((row, idx) => {
            let updatedRow = { ...row };
        //Q26b
                if (isStringEmpty(row.lOT) || (parseInt(row.lOT) < 2) || (parseInt(row.lOT) > 40)) {
            result = false;
            updatedRow.showColBError = true;
        }
                else {
                    updatedRow.showColBError = false;

                    const lotsAbove = getLotsAboveMatchingIndex(row.cohorttreatmentid, bispecifictreatment);
                    if (lotsAbove.includes(row.lOT)) {
                        result = false;
                        updatedRow.showColBDuplicateLotError = true;
                    }
                    else {
                        updatedRow.showColBDuplicateLotError = false;
                    }
            }



            if (row.cohorttreatmentid != RRMMCohortTreatment.CARVYKTI && row.cohorttreatmentid != RRMMCohortTreatment.ABECMA) {
        //Q26d
        if (isStringEmpty(row.lastDate) && isStringEmpty(row.lastDateRadio) && isStringEmpty(row.lastDateUnk)) {
            result = false;
            updatedRow.showColDError = true;
        }
        else {
            if (row.lastDateRadio === 1 && isStringEmpty(row.lastDate)) {
                result = false;
                updatedRow.showColDError = true;
            }
            else
                updatedRow.showColDError = false;
        }


              

          
                //Q26e
                if ((isStringEmpty(row.noOfAdmission) && isStringEmpty(row.noOfAdmissionRadio) && isStringEmpty(row.noOfAdmissionUnk)) ||
                    ((parseInt(row.noOfAdmission) < 1) || (parseInt(row.noOfAdmission) > 4000)) || (row.noOfAdmissionRadio === 1 && isStringEmpty(row.noOfAdmission))) {

               // if (isStringEmpty(row.noOfAdmission) && isStringEmpty(row.noOfAdmissionRadio) && isStringEmpty(row.noOfAdmissionUnk)) {
                    result = false;
                    updatedRow.showColEError = true;
                }
                else {
                    if (row.noOfAdmissionRadio === 1 && isStringEmpty(row.noOfAdmission)) {
                        result = false;
                        updatedRow.showColEError = true;
                    }
                    else
                        updatedRow.showColEError = false;
                }

             

                //Q26h
                if (
                    (isStringEmpty(row.doseAdministrationmg) && isStringEmpty(row.doseAdministrationRadiomg)) && 
                     (isStringEmpty(row.doseAdministrationkg) && isStringEmpty(row.doseAdministrationRadiokg)) &&
                     (isStringEmpty(row.doseAdministrationmgm) && isStringEmpty(row.doseAdministrationRadiomgm))
                    )
                   {

                    result = false;
                    updatedRow.showColHError = true;
                }
                else {
                    if ((row.doseAdministrationRadiomg === 1 && (isStringEmpty(row.doseAdministrationmg) || (parseInt(row.doseAdministrationmg) < 1) || (parseInt(row.doseAdministrationmg) > 4000))) || 
                        (row.doseAdministrationRadiokg === 1 && isStringEmpty(row.doseAdministrationkg) || (parseInt(row.doseAdministrationkg) < 1) || (parseInt(row.doseAdministrationkg) > 4000)) ||
                        (row.doseAdministrationRadiomgm === 1 && isStringEmpty(row.doseAdministrationmgm) || (parseInt(row.doseAdministrationmgm) < 1) || (parseInt(row.doseAdministrationmgm) > 4000)) 
                    ) {
                        result = false;
                        updatedRow.showColHError = true;
                    }
                    else
                        updatedRow.showColHError = false;
                }









                //Q26f
                if (isStringEmpty(row.discontinueAgent)) {
                    result = false;
                    updatedRow.showColFError = true;
                }
                else {
                    updatedRow.showColFError = false;
                }

                //Q26g
                if (row.discontinueAgent === 1) {
                    const isAnySelected = row.reasonForDiscontinuingMedications.some(item => item.isSelected);
                    if (isAnySelected || (row.reasonForDiscontinuingMedications[0].reasonUnk === true) || (row.reasonForDiscontinuingMedications[0].reasonUnk === 1)) {
                        updatedRow.showColGError = false;

                        const isReasonOtherSelected = row.reasonForDiscontinuingMedications.some(item => item.isSelected && item.reasonName.includes("Other") && (item.reasonText === null || item.reasonText === ''));
                        if (isReasonOtherSelected) {
                            result = false;
                            updatedRow.showColGOthError = true;
                        }
                        else
                            updatedRow.showColGOthError = false;
                    }
                    else {
                        result = false;
                        updatedRow.showColGError = true;
                    }
                }



                if (row.lastDateRadio === 1 && row.lastDate === "Invalid Date") {
                    result = false;
                    updatedRow.showColD_InvalidDate = true;
                }
                else if (row.lastDateRadio === 1 && row.lastDate && !DateInRange(
                    row.lastDate, dayjs("01-01-1920"), dayjs())) {
                    result = false;
                    updatedRow.showColD_InvalidDate = true;
                }
                else {
                    updatedRow.showColD_InvalidDate = false;



                    const startDt = (row.firstDate === null || row.firstDate === "") ? null : parseDate(row.firstDate);
                    const endDt = (row.lastDate === null || row.lastDate === "") ? null : parseDate(row.lastDate);
                    const endOfFollowUpDt = (row.endOfFollowUpDate === null || row.endOfFollowUpDate === "") ? null : parseDate(row.endOfFollowUpDate);



                    // Validate the parsed dates
                    if (row.lastDateUnk !== 1) {
                        if (startDt != null && endDt != null && endOfFollowUpDt != null) {
                            // Check if the last radiotherapy date is within the range
                            if (startDt <= endDt && endDt <= endOfFollowUpDt) {
                                updatedRow.showColD_LEFDateError = false;//valid
                            } else {
                                result = false;
                                updatedRow.showColD_LEFDateError = (row.lastDate === "" || row.lastDate === null) ? false : true;//in-valid
                            }
                        }
                    }





                    if (startDt != null && endDt === null && endOfFollowUpDt != null) {
                        // Check if the last radiotherapy date is within the range
                        if (startDt <= endOfFollowUpDt) {
                            updatedRow.showColD_EFDateError = false;//valid
                        } else {
                            result = false;
                            updatedRow.showColD_EFDateError = (row.firstDate === "" || row.firstDate === null) ? false : true;//in-valid
                        }
                    }

                    if (startDt != null && endDt !== null && endOfFollowUpDt == null) {
                        // Check if the last radiotherapy date is within the range
                        if (startDt <= endDt) {
                            updatedRow.showColD_SEFDateError = false;//valid
                        } else {
                            result = false;
                            updatedRow.showColD_SEFDateError = true;//in-valid
                        }
                    }
                }

            }

            if (row.cohorttreatmentid == RRMMCohortTreatment.CARVYKTI || row.cohorttreatmentid == RRMMCohortTreatment.ABECMA) {
                //Q26h
             
                if (
                    (isStringEmpty(row.doseAdministrationcell) && isStringEmpty(row.doseAdministrationRadiocell))
                ) {

                    result = false;
                    updatedRow.showColHError = true;
                }
                else {
                    if (
                        (row.doseAdministrationRadiocell === 1 && isStringEmpty(row.doseAdministrationcell) || (parseInt(row.doseAdministrationcell) < 1) || (parseInt(row.doseAdministrationcell) > 4000))
                    ) {
                        result = false;
                        updatedRow.showColHError = true;
                    }
                    else
                        updatedRow.showColHError = false;
                }
            }
            return updatedRow;
        });
        setbispecificTreatment(updatedRepeater);
        return result;
    }



    function parseDate(dateString) {
     
        const [month, day, year] = dateString.split("/");
      
        return new Date(year, month - 1, day);
    }

 
    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {
    

    
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

  useEffect(() => {
 const   sidebar = document.getElementById("mySidebar");
  const  main = document.getElementById("main");


    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });
    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div >
                    <SideBarMenu activePageId={SideMenuItems.BispecificsCartTreatmentCohort} />
                    <>
                    <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
                        </>
                    <div className="col-pad-left" id='main'>
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>BISPECIFIC/CAR-T TREATMENT COHORT</h4>
                            </div>
                            {showTopErrMsg && <div className="clean-error ">
                                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
                            </div>
                            }
                            <div className="survey-question-section">
                                <div className="survey-question-content calender-datefix-survey">
                                    <div className="question-answer">
                                        {/* Q26 */}
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q26.</span>
                                                <span className="quest-text-pad">
                                                   Describe the prescribed dosing information for each bispecific and/or CAR-T therapy that the patient has received.{" "}
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="" style={{ overflowX: 'auto' }}>

                                                    <table
                                                        style={{
                                                            border: "1px solid black",
                                                            borderCollapse: "collapse",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th style={{
                                                                    border: "1px solid black",
                                                                    padding: "8px",
                                                                    textAlign: "center",
                                                                }}>
                                                                    Medication
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center",
                                                                        minWidth: "135px"
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <span
                                                                        data-tooltip-id="encounterTip"
                                                                        className="modal-popup"
                                                                        onClick={() =>
                                                                            setShowHideHyperlinkModal(
                                                                                true
                                                                            )
                                                                        }
                                                                    >
                                                                        Line Of Therapy
                                                                    </span>
                                                                    {showHideHyperlinkModal && (
                                                                        <InfoPopUp
                                                                            description={
                                                                                hyperlinkTextLineOfTherapy
                                                                            }
                                                                            OkClick={() =>
                                                                                setShowHideHyperlinkModal(
                                                                                    false
                                                                                )
                                                                            }
                                                                        />
                                                                    )}{" "}
                                                                </th>
                                                                <th style={{
                                                                    border: "1px solid black",
                                                                    padding: "8px",
                                                                    textAlign: "center",
                                                                }}>
                                                                    Date of first administration
                                                                </th>
                                                                <th style={{
                                                                    border: "1px solid black",
                                                                    padding: "8px",
                                                                    textAlign: "center",
                                                                }}>
                                                                    Date of last administration
                                                                </th>
                                                                <th style={{
                                                                    border: "1px solid black",
                                                                    padding: "8px",
                                                                    textAlign: "center",
                                                                    minWidth: '195px'
                                                                }}>
                                                                    Number of administrations
                                                                </th>
                                                                <th style={{
                                                                    border: "1px solid black",
                                                                    padding: "8px",
                                                                    textAlign: "center",
                                                                    minWidth: '305px'
                                                                }}>
                                                                    Dose at first administration
                                                                </th>
                                                                <th style={{
                                                                    border: "1px solid black",
                                                                    padding: "8px",
                                                                    textAlign: "center",
                                                                    minWidth: '250px'
                                                                }}>
                                                                    Did patient discontinue agent?
                                                                </th>
                                                                <th style={{
                                                                    border: "1px solid black",
                                                                    padding: "8px",
                                                                    textAlign: "center",
                                                                    minWidth: '300px'
                                                                }}>
                                                                    Reason for discontinuing
                                                                </th>
                                                               
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {/* Populate static rows */}
                                                            {bispecificTreatment.length > 0 && bispecificTreatment?.map((data, idx) => (
                                                                <tr key={idx}>
                                                                   
                                                                        <td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                alignContent: "start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                            }}
                                                                        >
                                                                            { }
                                                                        <div>
                                                                            {data.cohortTreatmentName}
                                                                            {data.supscripttext == null ? (
                                                                                ''
                                                                            ) : (
                                                                                <>
                                                                                        <sup className="supscript">{data.supscripttext.slice(0, 2)}</sup>
                                                                                        {data.supscripttext.slice(2)}
                                                                                </>
                                                                            )}
                                                                            </div>
                                                                          
                                                                        </td>

                                                                    <td
                                                                        style={{
                                                                            border: "1px solid black",
                                                                            alignContent: "flex-start",
                                                                            textAlign: "center",
                                                                            padding: "10px",
                                                                          
                                                                        }}
                                                                    >
                                                                        <div className="">
                                                                            <label htmlFor={`q26b_${data.cohortTreatmentName}_lottxt_${idx}`}>
                                                                                <span className="">
                                                                                    <input
                                                                                        className="input-dash"
                                                                                        id={`q26b_${data.cohortTreatmentName}_lottxt_${idx}`}
                                                                                        name={`q26b_${data.cohortTreatmentName}_lottxt_${idx}`}
                                                                                        type="text"
                                                                                        value={data.lOT}
                                                                                        min={2}
                                                                                        max={40}
                                                                                        //maxLength={2}
                                                                                        onKeyDown={(e) => {
                                                                                            // Prevent entering '.', 'e', '+', '-', and any non-numeric characters
                                                                                            if (['.', 'e', '+', '-', 'E'].includes(e.key) || (e.key.length === 1 && !/[0-9]/.test(e.key))) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                    />
                                                                                </span>
                                                                            </label>
                                                                            {/*<label><span className="" style={{ textAlign: 'start' }}>(Range: 1 to 40)</span></label>*/}
                                                                        </div>

                                                                        <ErrorField style={{ padding: '0px' }}
                                                                            show={bispecificTreatment[idx].showColBError}
                                                                            message={BispecificCartTreatmentCohort_Msgs.q26b}
                                                                        />
                                                                        <ErrorField style={{ padding: '0px' }}
                                                                            show={bispecificTreatment[idx].showColBDuplicateLotError}
                                                                            message={BispecificCartTreatmentCohort_Msgs.q26duplicateb}
                                                                        />
                                                                    </td>


                                                                    <td
                                                                        style={{
                                                                            border: "1px solid black",
                                                                            alignContent: "start",
                                                                            textAlign: "center",
                                                                            padding: "8px",
                                                                        }}
                                                                    >
                                                                        { }
                                                                        <div className="radio-text-padding" style={{ display: 'flex' }}>
                                                                            <div>{data.cohortIndexDate}</div>

                                                                        </div>

                                                                    </td>


                                                                    {bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.CARVYKTI && bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.ABECMA ? (
                                                                        <td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                            }}
                                                                        >
                                                                            <div className="">
                                                                                <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={`q26d_LRadio_${data.cohortTreatmentName}_${idx}`}
                                                                                        name={`q26d_LRadio_${data.cohortTreatmentName}_${idx}`}
                                                                                        style={{ marginTop: '6px' }}
                                                                                        value={1}
                                                                                        checked={data.lastDateRadio === 1}
                                                                                        onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                    />
                                                                                    <span className="radio-text-padding datepicker-dependence calender-datefix" style={{ paddingLeft: '5px'}}>  
                                                                                        {/*<DateControl*/}
                                                                                        {/*    ctrlId={`q26d_LDate_${idx}`}*/}
                                                                                        {/*    HandleDateChange={HandleDateChange}*/}
                                                                                        {/*    defDate={data.lastDate}*/}
                                                                                        {/*    optionId={1}*/}
                                                                                        {/*    date={data.lastDate}*/}
                                                                                        {/*/>*/}
                                                                                        <DatePickerComponent
                                                                                            ctrlId={`q26d_LDate_${idx}`}
                                                                                            HandleDateChange={HandleDateChange}
                                                                                            minDate={dayjs("01-01-1920")}
                                                                                            maxDate={dayjs()}
                                                                                            locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                                                                            date={data.lastDate}
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                                <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                                    <label style={{ textAlign: 'start', display: 'flex', alignItems: 'flex-start' }}>

                                                                                    <input
                                                                                        type="radio"
                                                                                        id={`q26d_${data.cohortTreatmentName}_LUnk_${idx}`}
                                                                                        name={`q26d_${data.cohortTreatmentName}_LUnk_${idx}`}
                                                                                        style={{ marginTop: '6px' }}
                                                                                        value={1}
                                                                                        checked={data.lastDateUnk === 1}
                                                                                        onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                    />
                                                                                        <span className="radio-text-padding">
                                                                                            Date of last administration unknown
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <ErrorField style={{ padding: '0px' }}
                                                                                show={bispecificTreatment[idx].showColDError}
                                                                                message={BispecificCartTreatmentCohort_Msgs.q26d}
                                                                            />

                                                                            <ErrorField style={{ padding: '0px' }}
                                                                                show={bispecificTreatment[idx].showColD_SEFDateError}
                                                                                message={BispecificCartTreatmentCohort_Msgs.q26d_sef}
                                                                            />
                                                                            <ErrorField style={{ padding: '0px' }}
                                                                                show={bispecificTreatment[idx].showColD_LEFDateError}
                                                                                message={BispecificCartTreatmentCohort_Msgs.q26d_lef}
                                                                            />
                                                                            <ErrorField style={{ padding: '0px' }}
                                                                                show={bispecificTreatment[idx].showColD_EFDateError}
                                                                                message={BispecificCartTreatmentCohort_Msgs.q26d_sef}
                                                                            />
                                                                            <ErrorField style={{ padding: '0px' }}
                                                                                show={bispecificTreatment[idx].showColD_InvalidDate}
                                                                                message={CommonError_Msgs.InvalidDate}
                                                                            />
                                                                        </td>):
                                                                        (<td
                                                                        style={{
                                                                            border: "1px solid black",
                                                                            alignContent: "flex-start",
                                                                            textAlign: "center",
                                                                            padding: "8px",
                                                                            textAlign: 'start'
                                                                        }}
                                                                        >
                                                                            <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>
                                                                    </td>)
                                                                    }
                                                                    {bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.CARVYKTI && bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.ABECMA ? (

                                                                    <td
                                                                        style={{
                                                                            border: "1px solid black",
                                                                            alignContent: "flex-start",
                                                                          
                                                                            padding: "8px",
                                                                        }}
                                                                        >
                                                                            <div className="option-fbox" style={{width:'216px'} }>
                                                                            <label htmlFor={`q26e_${data.cohortTreatmentName}_Noa_${idx}`}>
                                                                                <input type="radio"
                                                                                    id={`q26e_${data.cohortTreatmentName}_Noa_${idx}`}
                                                                                    name={`q26e_${data.cohortTreatmentName}_Noa_${idx}`}
                                                                                    value={1}
                                                                                    checked={
                                                                                        data.noOfAdmissionRadio === 1
                                                                                    }
                                                                                    onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                />
                                                                            </label>
                                                                            <label htmlFor={`q26e_${data.cohortTreatmentName}_Noatxt_${idx}`}>
                                                                                <span className="radio-text-padding">
                                                                                    <input
                                                                                        className="input-dash"
                                                                                        id={`q26e_${data.cohortTreatmentName}_Noatxt_${idx}`}
                                                                                        name={`q26e_${data.cohortTreatmentName}_Noatxt_${idx}`}
                                                                                        type="text"
                                                                                        value={data.noOfAdmission}
                                                                                        min={1}
                                                                                            max={4000}
                                                                                           
                                                                                       // maxLength={3}
                                                                                            onKeyDown={(e) => {
                                                                                                // Prevent entering '.', 'e', '+', '-', and any non-numeric characters
                                                                                                if (['.', 'e', '+', '-', 'E'].includes(e.key) || (e.key.length === 1 && !/[0-9]/.test(e.key))) {
                                                                                                    e.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                        onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                    />
                                                                                </span>
                                                                            </label>
                                                                            {/*<label><span className="" style={{ marginLeft: '12px', textAlign: 'start' }}>(Range: 1 to 550)</span></label>*/}
                                                                        </div>

                                                                        <div className="">
                                                                            <input
                                                                                type="radio"
                                                                                id={`q26e_${data.cohortTreatmentName}_NoaUnk_${idx}`}
                                                                                name={`q26e_${data.cohortTreatmentName}_NoaUnk_${idx}`}
                                                                                value={1}
                                                                                checked={data.noOfAdmissionUnk === 1}
                                                                                onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                            />
                                                                            <label htmlFor={`q26e_${data.cohortTreatmentName}_NoaUnk_${idx}`}>
                                                                                <span className="radio-text-padding">
                                                                                    Unknown
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <ErrorField style={{ padding: '0px' }}
                                                                            show={bispecificTreatment[idx].showColEError}
                                                                            message={BispecificCartTreatmentCohort_Msgs.q26e}
                                                                        />
                                                                        </td>) :
                                                                        (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>
                                                                        </td>)
                                                                    }






                                                                    {bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.CARVYKTI && bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.ABECMA ? (

                                                                    <td
                                                                        style={{
                                                                            border: "1px solid black",
                                                                            alignContent: "flex-start",

                                                                            padding: "8px",
                                                                        }}
                                                                    >
                                                                        <div className="option-fbox" >
                                                                            <label htmlFor={`q26h1_${data.cohortTreatmentName}_mg_${idx}`}>
                                                                                <input type="radio"
                                                                                    id={`q26h1_${data.cohortTreatmentName}_mg_${idx}`}
                                                                                    name={`q26h1_${data.cohortTreatmentName}_mg_${idx}`}
                                                                                    value={1}
                                                                                    checked={
                                                                                        data.doseAdministrationRadiomg === 1
                                                                                    }
                                                                                    onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                />
                                                                            </label>
                                                                            <label htmlFor={`q26h1_${data.cohortTreatmentName}_mgtxt_${idx}`}>
                                                                                <span className="radio-text-padding">
                                                                                    <input
                                                                                        className="input-dash-custom"
                                                                                        id={`q26h1_${data.cohortTreatmentName}_mgtxt_${idx}`}
                                                                                        name={`q26h1_${data.cohortTreatmentName}_mgtxt_${idx}`}
                                                                                        type="text"
                                                                                        value={data.doseAdministrationmg}
                                                                                        min={1}
                                                                                        max={4000}

                                                                                        // maxLength={3}
                                                                                        onKeyDown={(e) => {
                                                                                            // Prevent entering '.', 'e', '+', '-', and any non-numeric characters
                                                                                            if (['.', 'e', '+', '-', 'E'].includes(e.key) || (e.key.length === 1 && !/[0-9]/.test(e.key))) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                    /> &nbsp; mg
                                                                                </span>
                                                                            </label>
                                                                            {/*<label><span className="" style={{ marginLeft: '12px', textAlign: 'start' }}>(Range: 1 to 550)</span></label>*/}
                                                                        </div>
                                                                        <br/>
                                                                        <div className="option-fbox" >
                                                                            <label htmlFor={`q26h2_${data.cohortTreatmentName}_kg_${idx}`}>
                                                                                <input type="radio"
                                                                                    id={`q26h2_${data.cohortTreatmentName}_kg_${idx}`}
                                                                                    name={`q26h2_${data.cohortTreatmentName}_kg_${idx}`}
                                                                                    value={1}
                                                                                    checked={
                                                                                        data.doseAdministrationRadiokg === 1
                                                                                    }
                                                                                    onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                />
                                                                            </label>
                                                                            <label htmlFor={`q26h2_${data.cohortTreatmentName}_kgtxt_${idx}`}>
                                                                                <span className="radio-text-padding">
                                                                                    <input
                                                                                            className="input-dash-custom"
                                                                                        id={`q26h2_${data.cohortTreatmentName}_kgtxt_${idx}`}
                                                                                        name={`q26h2_${data.cohortTreatmentName}_kgtxt_${idx}`}
                                                                                        type="text"
                                                                                        value={data.doseAdministrationkg}
                                                                                        min={1}
                                                                                        max={4000}

                                                                                        // maxLength={3}
                                                                                        onKeyDown={(e) => {
                                                                                            // Prevent entering '.', 'e', '+', '-', and any non-numeric characters
                                                                                            if (['.', 'e', '+', '-', 'E'].includes(e.key) || (e.key.length === 1 && !/[0-9]/.test(e.key))) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                    />
                                                                                </span> &nbsp; mg/kg
                                                                            </label>
                                                                            {/*<label><span className="" style={{ marginLeft: '12px', textAlign: 'start' }}>(Range: 1 to 550)</span></label>*/}
                                                                        </div>
                                                                        <br />
                                                                        <div className="option-fbox" >
                                                                            <label htmlFor={`q26h3_${data.cohortTreatmentName}_m_${idx}`}>
                                                                                <input type="radio"
                                                                                    id={`q26h3_${data.cohortTreatmentName}_m_${idx}`}
                                                                                    name={`q26h3_${data.cohortTreatmentName}_m_${idx}`}
                                                                                    value={1}
                                                                                    checked={
                                                                                        data.doseAdministrationRadiomgm === 1
                                                                                    }
                                                                                    onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                />
                                                                            </label>
                                                                            <label htmlFor={`q26h3_${data.cohortTreatmentName}_mtxt_${idx}`}>
                                                                                <span className="radio-text-padding">
                                                                                    <input
                                                                                            className="input-dash-custom"
                                                                                        id={`q26h3_${data.cohortTreatmentName}_mtxt_${idx}`}
                                                                                        name={`q26h3_${data.cohortTreatmentName}_mtxt_${idx}`}
                                                                                        type="text"
                                                                                        value={data.doseAdministrationmgm}
                                                                                        min={1}
                                                                                        max={4000}

                                                                                        // maxLength={3}
                                                                                        onKeyDown={(e) => {
                                                                                            // Prevent entering '.', 'e', '+', '-', and any non-numeric characters
                                                                                            if (['.', 'e', '+', '-', 'E'].includes(e.key) || (e.key.length === 1 && !/[0-9]/.test(e.key))) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                    />
                                                                                </span> &nbsp; mg/m<sup>2</sup>
                                                                            </label>
                                                                            {/*<label><span className="" style={{ marginLeft: '12px', textAlign: 'start' }}>(Range: 1 to 550)</span></label>*/}
                                                                        </div>
                                                                            <br />
                                                                            <ErrorField style={{ padding: '0px' }}
                                                                                show={bispecificTreatment[idx].showColHError && (bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.CARVYKTI && bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.ABECMA)}
                                                                                message={BispecificCartTreatmentCohort_Msgs.q26h}
                                                                            />
                                                                        </td>
                                                                            ):
                                                                        (
                                                                            <td
                                                                                style={{
                                                                                    border: "1px solid black",
                                                                                    alignContent: "flex-start",

                                                                                    padding: "8px",
                                                                                }}
                                                                            >
                                                                            <div className="option-fbox" >
                                                                                <label htmlFor={`q26h4_${data.cohortTreatmentName}_cell_${idx}`}>
                                                                                    <input type="radio"
                                                                                        id={`q26h4_${data.cohortTreatmentName}_cell_${idx}`}
                                                                                        name={`q26h4_${data.cohortTreatmentName}_cell_${idx}`}
                                                                                        value={1}
                                                                                        checked={
                                                                                            data.doseAdministrationRadiocell === 1
                                                                                        }
                                                                                        onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                    />
                                                                                </label>
                                                                                <label htmlFor={`q26h4_${data.cohortTreatmentName}_celltxt_${idx}`}>
                                                                                    <span className="radio-text-padding">
                                                                                        <input
                                                                                                className="input-dash-custom"
                                                                                            id={`q26h4_${data.cohortTreatmentName}_celltxt_${idx}`}
                                                                                            name={`q26h4_${data.cohortTreatmentName}_celltxt_${idx}`}
                                                                                            type="text"
                                                                                            value={data.doseAdministrationcell}
                                                                                            min={1}
                                                                                            max={4000}

                                                                                            // maxLength={3}
                                                                                            onKeyDown={(e) => {
                                                                                                // Prevent entering '.', 'e', '+', '-', and any non-numeric characters
                                                                                                if (['.', 'e', '+', '-', 'E'].includes(e.key) || (e.key.length === 1 && !/[0-9]/.test(e.key))) {
                                                                                                    e.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                        />
                                                                                    </span> &nbsp; x 10<sup>6</sup>&nbsp;cells
                                                                                    {bispecificTreatment[idx].cohorttreatmentid == RRMMCohortTreatment.CARVYKTI && (<>/kg</>)}
                                                                                </label>
                                                                                {/*<label><span className="" style={{ marginLeft: '12px', textAlign: 'start' }}>(Range: 1 to 550)</span></label>*/}
                                                                                </div>
                                                                                <ErrorField style={{ padding: '0px' }}
                                                                                    show={bispecificTreatment[idx].showColHError && (bispecificTreatment[idx].cohorttreatmentid == RRMMCohortTreatment.CARVYKTI || bispecificTreatment[idx].cohorttreatmentid == RRMMCohortTreatment.ABECMA)}
                                                                                    message={BispecificCartTreatmentCohort_Msgs.q26h}
                                                                                />
                                                                            </td>)}
                                                                        
                                                                  



















                                                                    {bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.CARVYKTI && bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.ABECMA ? (

                                                                    <td
                                                                        style={{
                                                                            border: "1px solid black",
                                                                            alignContent: "flex-start",
                                                                            padding: "8px",
                                                                        }}
                                                                    >
                                                                        <div className="">
                                                                            <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`q26f_${data.cohortTreatmentName}_PdaYes_${idx}`}
                                                                                    name={`q26f_${data.cohortTreatmentName}_PdaYes_${idx}`}
                                                                                    value={1}
                                                                                    checked={data.discontinueAgent === 1}
                                                                                   onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                    style={{ marginTop: '6px' }}
                                                                                />
                                                                                <label htmlFor={`q26f_${data.cohortTreatmentName}_PdaYes_${idx}`} style={{ textAlign: 'start' }}>
                                                                                    <span className="radio-text-padding">
                                                                                        Yes, agent discontinued 
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="">
                                                                            <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`q26f_${data.cohortTreatmentName}_PdaNo_${idx}`}
                                                                                    name={`q26f_${data.cohortTreatmentName}_PdaNo_${idx}`}
                                                                                    value={2}
                                                                                    checked={data.discontinueAgent === 2}
                                                                                    onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                    style={{ marginTop: '6px' }}
                                                                                />
                                                                                <label htmlFor={`q26f_${data.cohortTreatmentName}_PdaNo_${idx}`} style={{ textAlign: 'start' }}>
                                                                                    <span className="radio-text-padding">
                                                                                        No, medication ongoing at time of last encounter
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="">
                                                                            <div className="option-without-input">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`q26f_${data.cohortTreatmentName}_PdaUnk_${idx}`}
                                                                                    name={`q26f_${data.cohortTreatmentName}_PdaUnk_${idx}`}
                                                                                    value={99}
                                                                                    checked={data.discontinueAgent === 99}
                                                                                    onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, null, null)}
                                                                                />
                                                                                <label htmlFor={`q26f_${data.cohortTreatmentName}_PdaUnk_${idx}`}>
                                                                                    <span className="radio-text-padding">
                                                                                        Unknown
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorField style={{ padding: '0px' }}
                                                                            show={bispecificTreatment[idx].showColFError}
                                                                            message={BispecificCartTreatmentCohort_Msgs.q26f}
                                                                        />
                                                                        </td>) :

                                                                        (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>
                                                                        </td>)
                                                                    }
                                                                    {bispecificTreatment[idx].showColG && (bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.CARVYKTI && bispecificTreatment[idx].cohorttreatmentid != RRMMCohortTreatment.ABECMA)? (
                                                                        <td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <div className="">
                                                                                {data.reasonForDiscontinuingMedications?.map((item, itemidx) => (
                                                                                    <div className="" key={itemidx}>
                                                                                        <div className="" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                                            <input type="checkbox"
                                                                                                key={item.reasonid}
                                                                                                id={`discont_${data.cohortTreatmentName}_${item.reasonName + item.supscripttext}_${itemidx}`}
                                                                                                name={`discont_${data.cohortTreatmentName}_${item.reasonName + item.supscripttext}_${itemidx}`}
                                                                                                style={{ marginTop: '6px' }}
                                                                                                checked={
                                                                                                    item.isSelected
                                                                                                }
                                                                                               onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, item.reasonName + item.supscripttext, idx, itemidx)}
                                                                                            />
                                                                                            <label htmlFor={`discont_${data.cohortTreatmentName}_${item.reasonName + item.supscripttext}_${itemidx}`}>
                                                                                                <span className="radio-text-padding">
                                                                                                    {item.reasonName}
                                                                                                    {item.supscripttext === null ? (
                                                                                                        ''
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <sup className="supscript">{item.supscripttext.slice(0, 2)}</sup>
                                                                                                            {item.supscripttext.slice(2)}
                                                                                                        </>
                                                                                                    )}
                                                                                                </span>
                                                                                            </label>
                                                                                           

                                                                                        </div>
                                                                                        {item.reasonName.includes('Other') &&
                                                                                                (<label htmlFor={`discont_${data.cohortTreatmentName}_${item.reasonName}txt_${itemidx}`}>
                                                                                            <span className="radio-text-padding">
                                                                                                <textarea style={{ width:'264px' }}
                                                                                                            //className="input-dash"
                                                                                                        id={`discont_${data.cohortTreatmentName}_${item.reasonName}txt_${itemidx}`}
                                                                                                        name={`discont_${data.cohortTreatmentName}_${item.reasonName}txt_${itemidx}`}
                                                                                                            type="text"
                                                                                                            value={item.reasonText}
                                                                                                            onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, item.reasonName + item.supscripttext, idx, itemidx)}
                                                                                                            maxLength={300}
                                                                                                        />
                                                                                                    </span>
                                                                                                </label>)}
                                                                                    </div>
                                                                                ))}
                                                                                <div className="">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={`discont_unk_${data.cohortTreatmentName}_${idx}`}
                                                                                        name={`discont_unk_${data.cohortTreatmentName}_${idx}`}
                                                                                        value={1}
                                                                                        checked={data.reasonForDiscontinuingMedications[0].reasonUnk ? 1 : ""}
                                                                                        onChange={(e) => HandleRepeaterFieldChange(e, data.cohortTreatmentName, null, idx, null)}
                                                                                    />
                                                                                    <label htmlFor={`discont_unk_${data.cohortTreatmentName}`} >
                                                                                        <span className="radio-text-padding">Unknown</span></label>
                                                                                </div>
                                                                            </div>
                                                                            <ErrorField style={{ padding: '0px' }}
                                                                                show={bispecificTreatment[idx].showColGError}
                                                                                message={BispecificCartTreatmentCohort_Msgs.q26g}
                                                                            />
                                                                            <ErrorField style={{ padding: '0px' }}
                                                                                show={bispecificTreatment[idx].showColGOthError}
                                                                                message={BispecificCartTreatmentCohort_Msgs.q26ga}
                                                                            />
                                                                        </td>) : (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)}

                                                                    
                                                             
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>

    )
}

export default BispecificsCartTreatmentCohort